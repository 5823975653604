import React from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Button, Typography, useTheme, Theme} from "@mui/material";
import {ChevronLeft} from "@mui/icons-material/";
import {resolveNavigationItem} from "./MenuBar";
import WithSpace from "./WithSpace";
import {WHITE} from "../../config";

/**
 * Breadcrumbs
 * @return {React.ReactElement}
 */
function Breadcrumbs():React.ReactElement {
    const navigate = useNavigate();
    const location=useLocation();
    const theme:Theme=useTheme();
    const navItem=resolveNavigationItem(window.location.pathname);

    return (
        <WithSpace sx={{height: "81.333px", display: "flex", alignItems: "center", color: WHITE, gap: {xs: "24px", sm: "42.5px"}, backgroundColor: theme.palette.primary.main}}>
            <Button
                sx={{
                    color: "inherit",
                    backgroundColor: "inherit",
                    border: "solid 1px white",
                    borderRadius: "4px",
                    padding: "8px 22px",
                    fontSize: "15px",
                    lineHeight: "26px",
                    letterSpacing: "0.46px",
                    textTransform: "capitalize",
                    boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
                }}
                onClick={(args:React.MouseEvent) => {
                    if (navItem?.onDialogClick) navItem.onDialogClick(args);
                    else if (location?.state?.from) navigate(location.state.from);
                    else navigate("/");
                }}
                startIcon={<ChevronLeft />}
            >
                {navItem?.onDialogClick?"Exit":"Back"}
            </Button>
            <Typography sx={{fontSize: "20px", fontWeight: "700", textTransform: "capitalize"}}>
                {navItem?.label}
            </Typography>
        </WithSpace>
    );
}

export default Breadcrumbs;
