import React from "react";
import {Alert as AlertBase, AlertTitle, AlertColor, Box, Theme, SxProps} from "@mui/material";

interface Props{
    severity:AlertColor
    title:string
    body:string
    status:boolean|undefined
    sx?: {box: SxProps<Theme>}
}

/**
 * Alert
 * @param {Props} props
 * @return {React.ReactElement|null}
 */
function Alert(props:Props):React.ReactElement|null {
    if (!props.status) return null;
    return (
        <Box sx={props.sx?.box}>
            <AlertBase variant="filled" severity={props.severity}>
                <AlertTitle>{props.title}</AlertTitle>
                {props.body}
            </AlertBase>
        </Box>
    );
}

export default Alert;
