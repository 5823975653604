import React from "react";
import {Box, Grid, Typography, useTheme, Theme, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import DCAS_LOGO from "../../static/DCAS-Logo.png";
import {MAIN_NAVIGATION, navigateExternal, resolveNavigationItem} from "./MenuBar";
import {NavigationItemType} from "../../types";
import {APP_PATHS, WHITE} from "../../config";
import WithSpace from "./WithSpace";
import {useContext} from "../generics/Context";

export const FOOTER_NAVIGATION:NavigationItemType[] = [
    ...MAIN_NAVIGATION,
    {
        key: "PRIVACY_POLICY",
        label: "Privacy Policy",
        path: APP_PATHS.PRIVACY_POLICY,
        external: true,
    },
    {
        key: "TERMS_OF_USE",
        label: "Terms of Use",
        path: APP_PATHS.TERMS_OF_USE,
        external: true,
    },
    {
        key: "ACCESSIBILITY",
        label: "Accessibility",
        path: APP_PATHS.ACCESSIBILITY,
        external: true,
    },
];

interface ListItemProps {
    keys: string[],
    sx: {
        listItem?: object,
        box?:object
        fontWeight?: number | string,
    }
}

const PRIMARY_NAV_KEYS:ListItemProps = {
    sx: {
        box: {
            display: "block",
        },
        listItem: {
            "&:not(:last-child)": {
                marginBottom: "10px",
            },
        },
    },
    keys: ["HOME", "DATA_EXPORT", "FAQ"],
};

const QUICK_LINKS_KEYS:ListItemProps = {
    sx: {
        box: {
            justifyContent: "left",
            gap: "10px",
        },
        listItem: {
            "&:not(:last-child)": {
                paddingRight: "10px",
                borderRight: "2px solid white",
            },
        },
        fontWeight: 400,
    },
    keys: ["DEM_SALESFORCE", "AGENCY_PORTAL", "ENERGY_TOOLS"],
};
const SECONDARY_NAV_KEYS:ListItemProps = {
    sx: {
        box: {
            justifyContent: "right",
            gap: "10px",
        },
        listItem: {
            "&:not(:last-child)": {
                paddingRight: "10px",
                borderRight: "2px solid white",
            },
        },
    },
    keys: ["PRIVACY_POLICY", "TERMS_OF_USE", "ACCESSIBILITY"],
};

const MOBILE_NAV_KEYS:ListItemProps = {
    sx: {
        box: {
            gap: "10px",
        },
        listItem: {
            paddingRight: "10px",
            "&:not(:last-child)": {
                borderRight: "2px solid white",
            },
        },
    },
    keys: [...PRIMARY_NAV_KEYS.keys, ...QUICK_LINKS_KEYS.keys],
};

const MOBILE_SECONDARY_NAV_KEYS:ListItemProps = {
    sx: {
        box: {
            justifyContent: "center",
            gap: "10px",
        },
        listItem: {
            paddingRight: "10px",
            "&:not(:last-child)": {
                borderRight: "2px solid white",
            },
        },
    },
    keys: [...SECONDARY_NAV_KEYS.keys],
};

/**
 * Footer
 * @return {React.ReactElement}
 */
function Footer():React.ReactElement {
    const theme:Theme=useTheme();
    const context=useContext();
    const navigate=useNavigate();

    /**
     * onNavClick
     * @param {NavigationItemType} item
     * @param {React.MouseEvent<HTMLElement>} args
     * @returns {void}
     */
    const onNavClick=(item: NavigationItemType, args:React.MouseEvent<HTMLElement>):void => {
        if (item.path && !item.external) {
            const n:NavigationItemType|undefined=resolveNavigationItem(window.location.pathname);
            if (n?.onDialogClick) context.setState({..._.cloneDeep(context.state), dialog: "PROMPT_DIALOG", to: item.path});
            else navigate(item.path as string, {state: {from: window.location.pathname}});
        } else if (item.path && item.external) navigateExternal(item.path as string);
    };

    /**
     * constructListItems
     * @param {ListItemProps} items
     * @return {React.ReactElement}
     */
    const constructListItems=(items:ListItemProps):any => (
        <Box sx={{flexWrap: "wrap", justifyContent: "center", display: "flex", ...items.sx.box}}>
            {items.keys.map((item:string) => (
                <Box key={item} sx={{...items.sx.listItem}}>
                    <Typography
                        lineHeight={1}
                        fontWeight={items.sx.fontWeight||700}
                        fontSize={14}
                        whiteSpace="nowrap"
                        sx={{
                            cursor: "pointer",
                            color: WHITE,
                            textDecoration: "none",
                            whiteSpace: "nowrap",
                        }}
                        onClick={(args:React.MouseEvent<HTMLElement>):void => { onNavClick(FOOTER_NAVIGATION.find((i) => i.key === item) as NavigationItemType, args); }}
                    >
                        {FOOTER_NAVIGATION.find((i) => i.key === item)?.label}
                    </Typography>
                </Box>
            ))}
        </Box>
    );

    const logoImg = (
        <Box
            component="img"
            sx={{
                height: "auto",
                width: "250px",
                mixBlendMode: "lighten",
            }}
            alt="DCAS"
            src={DCAS_LOGO}
        />
    );

    /**
     * constructAddress
     * @param {any} spanStyle
     * @param {any} addressStyle
     * @return {React.ReactNode}
     */
    const constructAddress = (spanStyle:any, addressStyle?:any):React.ReactNode => (
        <address style={addressStyle}>
            {[
                "City Of New York",
                "2024 All Rights Reserved",
                "NYC is a trademark and service mark",
                "of the City of New York",
            ].map((s:string) => <span key={s} style={{fontWeight: "700", ...spanStyle}}>{s}</span>)}
        </address>
    );

    const desktopScreen = (
        <Grid container sx={{display: {xs: "none", md: "flex"}}}>
            <Grid item xs={4}>
                <Box sx={{paddingBottom: "32px"}}>
                    {constructListItems(PRIMARY_NAV_KEYS)}
                </Box>
                <Box sx={{display: "flex", flexDirection: "column", gap: "10px"}}>
                    <Typography color={WHITE} fontWeight={700} fontSize={14}>Quick Links</Typography>
                    {constructListItems(QUICK_LINKS_KEYS)}
                </Box>
            </Grid>
            <Grid item xs={4} sx={{display: "flex", justifyContent: "center", alignItems: "flex-start"}}>
                {logoImg}
            </Grid>
            <Grid item sx={{display: "flex", flexDirection: "column"}} xs={4}>
                <Box sx={{
                    alignSelf: "flex-end",
                    marginBottom: "32px",
                    maxWidth: "260px",
                    color: WHITE,
                    fontWeight: "700",
                    fontSize: "14px",
                    "& span": {
                        display: "block", marginBottom: "3px",
                    },
                    "& address": {
                        fontStyle: "normal",
                    }}}
                >
                    <address>
                        {constructAddress({textAlign: "right"})}
                    </address>
                </Box>
                {constructListItems(SECONDARY_NAV_KEYS)}
            </Grid>
        </Grid>
    );

    return (
        <WithSpace sx={{
            backgroundColor: theme.palette.secondary.main,
            padding: "54px 0px",
        }}
        >
            {/* desktop screen size */}
            {desktopScreen}
            {/* mobile screen size */}
            <Stack direction="column" display={{xs: "flex", md: "none"}} sx={{alignItems: "center", gap: 3}}>
                {logoImg}
                <Box sx={{display: "flex"}}>
                    {constructListItems(MOBILE_NAV_KEYS)}
                </Box>
                <Box>
                    {constructAddress({textAlign: "center"}, {color: WHITE, display: "flex", flexDirection: "column", alignItems: "center"})}
                </Box>
                <Box sx={{display: "flex"}}>
                    {constructListItems(MOBILE_SECONDARY_NAV_KEYS)}
                </Box>
            </Stack>
        </WithSpace>
    );
}

export default Footer;
