import React, {useState, Dispatch, SetStateAction} from "react";
import {useAuth0, Auth0ContextInterface, User} from "@auth0/auth0-react";
import {AppBar, useTheme, Theme, Box, CssBaseline, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Divider, List, Drawer, ListItem, ListItemButton, ListItemText, Chip} from "@mui/material";
import {Person, Help, Login, Menu as MenuIcon, VerifiedUserOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import {NavigationItemType, MenuProps} from "../../types";
import LOGO_PRIMARY from "../../static/logo_primary.svg";
import {APP_PATHS, env, REACT_APP_VERSION, WHITE} from "../../config/index";
import WithSpace from "./WithSpace";
import {useContext} from "../generics/Context";

interface State {
    anchorEl:null|HTMLElement;
    subMenu:null|string;
    mobileOpen:boolean;
}

export const MAIN_NAVIGATION:NavigationItemType[] = [
    {
        key: "HOME",
        label: "Home",
        path: APP_PATHS.HOME,
    },
    {
        key: "DATA_EXPORT",
        label: "Data Export",
        path: APP_PATHS.BASIC_DATA_EXPORT,
        onDialogClick: (args:React.MouseEvent) => {},
    },
    {
        key: "DEM_SALESFORCE",
        label: "DEM Salesforce",
        path: APP_PATHS.DEM_SALESFORCE,
        external: true,
    },
    {
        key: "AGENCY_PORTAL",
        label: "Agency Portal",
        path: APP_PATHS.AGENCY_PORTAL,
        external: true,
    },
    {
        key: "ENERGY_TOOLS",
        label: "Energy Tools",
        path: APP_PATHS.ENERGY_TOOLS,
        external: true,
    },
    {
        key: "FAQ",
        label: "Frequently Asked Questions",
        path: APP_PATHS.FAQ,
    },
    {
        key: "ACCOUNT",
        label: "Account",
        subMenu: [
            {
                key: "signout",
                label: "Sign Out",
                onClick: undefined,
            },
        ],
    },
];

/**
 * resolveNavigationItem
 * @param {string} path
 * @return {NavigationItemType|undefined}
 */
export const resolveNavigationItem=(path:string):NavigationItemType|undefined => {
    for (let i=0; i<MAIN_NAVIGATION.length; i+=1) {
        if (MAIN_NAVIGATION[i].path===path) return MAIN_NAVIGATION[i];
        if (Array.isArray(MAIN_NAVIGATION[i].subMenu)) {
            const item:NavigationItemType|undefined=MAIN_NAVIGATION[i]?.subMenu?.find((n:NavigationItemType) => n.path===path);
            if (item) return item;
        }
    }
    return undefined;
};

/**
 * navigateExternal
 * @param {string} url
 * @return {void}
 */
export const navigateExternal=(url:string):void => {
    if (!url) return;
    window.open?.(url, "_blank");
};

/**
 * MenuBar
 * @return {React.ReactElement}
 */
function MenuBar():React.ReactElement {
    const context=useContext();
    const {isAuthenticated, logout, loginWithRedirect}:Auth0ContextInterface<User>= useAuth0();
    const navigate = useNavigate();
    const theme:Theme=useTheme();
    const [state, setState]:[State, Dispatch<SetStateAction<State>>]=useState<State>({
        anchorEl: null,
        subMenu: null,
        mobileOpen: false,
    });

    const container = window !== undefined ? () => window.document.body : undefined;
    const ENVIRONMENT=(env.REACT_APP_ENVIRONMENT!==undefined && env.REACT_APP_ENVIRONMENT!=="production" && (
        <Box><Chip sx={{textTransform: "capitalize"}} icon={<VerifiedUserOutlined />} label={`${env.REACT_APP_ENVIRONMENT} ${REACT_APP_VERSION||""}`} color="warning" /></Box>
    ));

    // set BasicDataExport onDialogClick
    (resolveNavigationItem(APP_PATHS.BASIC_DATA_EXPORT) as NavigationItemType).onDialogClick=(args:React.MouseEvent):void => {
        context.setState({..._.cloneDeep(context.state), dialog: "PROMPT_DIALOG"});
    };

    const ACCOUNT_ITEM:NavigationItemType={
        ...MAIN_NAVIGATION.find((i) => i.key === "ACCOUNT")?.subMenu?.[0],
        onClick: (args:React.MouseEvent<HTMLElement>) => {
            const n:NavigationItemType|undefined=resolveNavigationItem(window.location.pathname);
            if (n?.onDialogClick) context.setState({..._.cloneDeep(context.state), dialog: "PROMPT_DIALOG", to: "/?signout=false"});
            else logout({logoutParams: {returnTo: `${window.location.origin}/?signout=true`}});
        },
    } as NavigationItemType;

    /**
     * handleDrawerToggle
     * @param {React.MouseEvent<HTMLElement>} args
     * @return {void}
     */
    const handleDrawerToggle = (args: React.MouseEvent<HTMLElement>):void => {
        setState((prevState) => ({...state, mobileOpen: !prevState.mobileOpen}));
    };

    /**
     * handleMenu
     * @param {React.MouseEvent<HTMLElement>} args
     * @return {void}
     */
    const handleMenu = (args: React.MouseEvent<HTMLElement>):void => {
        setState({...state, anchorEl: args.currentTarget, subMenu: `${args.currentTarget.id}_MENU`});
    };

    /**
     * handleClose
     * @param {Callback} callback
     * @return {Function}
     */
    const handleClose = (callback: any = null) => (args: React.MouseEvent):void => {
        if (callback) callback();
        setState({...state, anchorEl: null, subMenu: null});
    };

    /**
     * onNavClick
     * @param {NavigationItemType} item
     * @param {React.MouseEvent<HTMLElement>} args
     * @returns {void}
     */
    const onNavClick=(item: NavigationItemType, args:React.MouseEvent<HTMLElement>):void => {
        if (item.path && !item.external) {
            const n:NavigationItemType|undefined=resolveNavigationItem(window.location.pathname);
            if (n?.onDialogClick) context.setState({..._.cloneDeep(context.state), dialog: "PROMPT_DIALOG", to: item.path});
            else navigate(item.path as string, {state: {from: window.location.pathname}});
        } else if (item.path && item.external) navigateExternal(item.path as string);
        else if (item.subMenu) handleMenu(args);
    };

    /**
     * constructListItem
     * @param {NavigationItemType} props
     * @returns {React.ReactElement}
     */
    const constructListItem=(props:NavigationItemType):React.ReactElement => (
        <ListItem key={props.key} disablePadding>
            <ListItemButton onClick={(e) => props.onClick?.(e) || onNavClick(props, e)} sx={{textAlign: "center"}} disabled={props.disabled} disableRipple>
                <ListItemText primary={props.label} />
            </ListItemButton>
        </ListItem>
    );

    /**
     * mobileDrawer
     * @return {React.ReactElement}
     */
    const mobileDrawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign: "center"}}>
            <Typography variant="h6" sx={{my: 2}}>Energy Data NYC</Typography>
            <Divider />
            <List>
                {MAIN_NAVIGATION.slice(0, MAIN_NAVIGATION.length - 2).map((item) => (
                    <Box key={item.key}>
                        {!item.subMenu ? constructListItem(item) : item.subMenu.map((subItem) => constructListItem(subItem))}
                    </Box>
                ))}
            </List>
            <List>
                <Divider />
                {constructListItem(MAIN_NAVIGATION.find((i) => i.key === "FAQ") as NavigationItemType)}
                {constructListItem(ACCOUNT_ITEM)}
            </List>
            {ENVIRONMENT}
        </Box>
    );

    /**
     * constructDropdownMenu
     * @param {props} MenuProps
     * @return {React.ReactElement}
     */
    const constructDropdownMenu=(props:MenuProps):React.ReactElement => (
        <Menu
            anchorEl={state.anchorEl}
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            open={(`${props.key}_MENU`) === state.subMenu}
            onClick={handleClose()}
            onClose={handleClose()}
            key={`${props.key}_MENU`}
            keepMounted
            disableScrollLock
        >
            {props.subMenu.map((subItem:NavigationItemType) => (
                <MenuItem disabled={subItem.disabled} onClick={subItem.onClick||((args:React.MouseEvent<HTMLElement>) => onNavClick(subItem, args))} key={subItem.key} disableRipple>
                    {subItem.label}
                </MenuItem>
            ))}
        </Menu>
    );

    /**
     * constructAppBar
     * @return {React.ReactElement}
     */
    const constructAppBar=():React.ReactElement => (
        <>
            <AppBar
                component="nav"
                position="fixed"
                sx={{backgroundColor: isAuthenticated?WHITE:theme.palette.primary.main, boxShadow: "none"}}
            >
                <WithSpace>
                    <Toolbar sx={{height: {xs: "80px", lg: "100px"}, justifyContent: "space-between", padding: "0 !important"}}>
                        {/* logo */}
                        {
                            isAuthenticated ? (
                                <Box
                                    onClick={(args:React.MouseEvent<HTMLElement>):void => (onNavClick(MAIN_NAVIGATION.find((n:NavigationItemType) => n.path==="/") as NavigationItemType, args))}
                                    component="img"
                                    sx={{height: {xs: "40px", lg: "50px"}, width: "auto", display: "flex", cursor: "pointer"}}
                                    alt="Energy Data NYC"
                                    src={LOGO_PRIMARY}
                                />
                            ) : <Box /> // empty box to maintain alignment
                        }
                        {/* nav */}
                        <Box sx={{display: {xs: "none", lg: "flex", alignItems: "center"}}}>
                            {ENVIRONMENT}
                            {/* Main Navigation */}
                            {MAIN_NAVIGATION.slice(0, MAIN_NAVIGATION.length - 2).map((item) => { // Do not include FAQ
                                if (!isAuthenticated) return null;
                                return (
                                    <Box key={item.key} sx={{display: "flex"}}>
                                        <Button
                                            id={item.key}
                                            onClick={(e) => onNavClick(item, e)}
                                            sx={{
                                                textTransform: "none",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                color: "#5D6A70",
                                                margin: "0 12px",
                                            }}
                                            disableRipple
                                        >
                                            {item.label}
                                        </Button>
                                        {item.subMenu && (constructDropdownMenu({key: item.key, subMenu: item.subMenu}))}
                                    </Box>
                                );
                            })}
                            {/* FAQ & Logout buttons */}
                            {isAuthenticated && (
                                <>
                                    {/* FAQ */}
                                    <IconButton
                                        size="large"
                                        aria-label="Faq"
                                        onClick={(args:React.MouseEvent<HTMLElement>):void => (onNavClick(MAIN_NAVIGATION.find((i) => i.key === "FAQ") as NavigationItemType, args))}
                                        color="secondary"
                                        disableRipple
                                    >
                                        <Help />
                                    </IconButton>
                                    {/* ACCOUNT */}
                                    <IconButton id="ACCOUNT" size="large" aria-label="Account of current user" aria-haspopup="true" onClick={handleMenu} color="secondary" disableRipple><Person /></IconButton>
                                    {constructDropdownMenu({key: "ACCOUNT", subMenu: [ACCOUNT_ITEM]})}
                                </>
                            )}
                        </Box>
                        {/* sign-in */}
                        {/* {!isAuthenticated && (<Button variant="text" sx={{color: WHITE, textTransform: "capitalize"}} startIcon={<Login />} onClick={(args:React.MouseEvent):void => { loginWithRedirect(); }}>Sign In</Button>)} */}
                        {/* mobile-menu button */}
                        {isAuthenticated && (<IconButton color="inherit" aria-label="Open Drawer" edge="start" onClick={handleDrawerToggle} sx={{display: {lg: "none"}}} disableRipple><MenuIcon sx={{color: "#000"}} /></IconButton>)}
                    </Toolbar>
                </WithSpace>
            </AppBar>
            {isAuthenticated && (
                <nav>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={state.mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{keepMounted: true}}
                        sx={{
                            display: {xs: "block", lg: "none"},
                            "& .MuiDrawer-paper": {boxSizing: "border-box", width: 280},
                        }}
                        disableScrollLock
                    >
                        {mobileDrawer}
                    </Drawer>
                </nav>
            )}
        </>
    );

    return (
        <Box sx={{display: "flex"}}>
            <CssBaseline />
            {constructAppBar()}
        </Box>
    );
}

export default MenuBar;
